/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAzXHd9ag62k8bzrs3gT72Gvh50-dXi0HA",
  "appId": "1:285592999083:web:d42c85b7d22f2f7fe4d3ee",
  "authDomain": "schooldog-i-rabun-ga.firebaseapp.com",
  "measurementId": "G-WRP5KCDHRR",
  "messagingSenderId": "285592999083",
  "project": "schooldog-i-rabun-ga",
  "projectId": "schooldog-i-rabun-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-rabun-ga.appspot.com"
}
